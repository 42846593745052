var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4e3a6ea18574abc194059ef0e85683851b54c70e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

import { envForThirdPartyTools, sentryOptions } from 'config/constants'

if (sentryOptions.enabled) {
  Sentry.init({
    dsn: sentryOptions.dsn,
    integrations: [Sentry.replayIntegration()],
    sendDefaultPii: false,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    environment: envForThirdPartyTools,
    beforeSend(event) {
      // Get the user agent from the request
      const userAgent = window.navigator.userAgent

      // Define the user agent string of the Applebot crawler
      const applebotUserAgent = 'Applebot'

      // Check if the user agent matches the Applebot crawler
      if (userAgent && userAgent.includes(applebotUserAgent)) {
        return null // Return null to ignore the error
      }

      return event
    },
  })
}
